import * as React from "react";

import Shell from "../components/Shell";

export default () => {
    return (
        <Shell>
            <div className="page contact">
                <h1>Contact</h1>
                <p>
                    If you have an idea for a piece of art but can’t find the
                    right fit, please contact me. I understand that your idea is
                    as unique as the person that made it and I am happy to work
                    through your ideas with you and customise commission for
                    you.
                </p>
                <p>
                    Prices will depend on the materials, subject matter and
                    sizing. However, due to Covid-19 I am currently doing pieces
                    of moderate simplicity and around A4 will be $150. Please
                    contact me with a description of what you would like, and I
                    can give you a more accurate quote.
                </p>
            </div>
        </Shell>
    );
};
